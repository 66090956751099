<template>
  <TopNav></TopNav>
  <h1>Kelime Sayısı Hesaplama</h1>
  <fieldset>
    <legend>Kelime Sayısı Hesaplama Aracı</legend>
    <label for="textField">Metin:</label>
    <textarea id="textField" name="textName" v-model="message" @input="onTextChange" rows="5" cols="33"></textarea>
    <br><br>
    Kelime Sayısı: <span class="blue">{{ numberOfWords }}</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "KelimeSayisi",
  data() {
    return {
      numberOfWords: 0
    }
  },
  components: {
    TopNav
  },
  methods: {
    onTextChange(e) {
      this.numberOfWords = e.currentTarget.value.match(/[a-zA-Z0-9ıçğüşöİĞÜÇÖ]+/g).length;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 110px;
}

textarea {
  vertical-align: top;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
